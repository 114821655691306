
import { defineComponent, PropType } from "vue";
import NavigatorItem from "@/components/common/nav/NavigatorItem.vue";
import { NavState, ScreenType } from "@/models/common";

export default defineComponent({
  name: "NavigatorDesktop",
  components: {
    NavigatorItem,
  },
  props: {
    state: {
      type: Object as PropType<NavState>,
      required: true,
    },
  },
  emits: ["login"],
  data() {
    return {
      aboutUs: [
        {
          title: "Nuestro ADN",
          target: "OurDNA",
        },
        {
          title: "Nuestros Fundadores",
          target: "Founders",
        },
        {
          title: "Junta Directiva",
          target: "Council",
        },
      ],
      missions: [
        {
          title: "ADN Misionero",
          target: "MissionsDNA",
        },
        {
          title: "Empresas Misioneras",
          target: "MissionsBusiness",
        },
        {
          title: "Proyectos Misioneros",
          target: "MissionsProjects",
        },
        {
          title: "Trabajo Social",
          target: "SocialWork",
        },
      ],
      resources: [
        /* {
          title: "Plan Felipe Integral",
          target: "https://recursos.centrocristiano.com.co/documentos-pfi",
          externalTarget: true,
        },
        {
          title: "Boletín Informativo",
          target: "https://recursos.centrocristiano.com.co/boletines-recursos",
          externalTarget: true,
        },
        {
          title: "Documentos Bioseguridad",
          target: "https://recursos.centrocristiano.com.co/documentos-recursos",
          externalTarget: true,
        },
        {
          title: "Manual de Imagen",
          target:
            "https://recursos.centrocristiano.com.co/institucionales-recursos",
          externalTarget: true,
        }, */
        /*{
          title: "Material P.F.I",
          target: "https://pfi.centrocristiano.com.co/bosquejos/",
          externalTarget: true,
        },*/
        {
          title: "Material Institucional",
          target: "2022Badge",
        },
        {
          title: "Política de Tratamiento de Datos",
          target:
            "https://api.centrocristiano.com.co/recursos/legal/POLITICA%20DE%20TRATAMIENTO%20DE%20DATOS%20-%20IGLESIA%20CENTRO%20CRISTIANO.pdf",
          externalTarget: true,
        },
        /* {
          title: "Amazonas 2021",
          target:
            "https://drive.google.com/drive/folders/1qyyPmg7EfYITP3xGAluBoefEEgrHE0W_?usp=sharing",
          externalTarget: true,
        },
        {
          title: "Bautizos 11/21",
          target:
            "https://drive.google.com/drive/folders/1rvECwz5NI1VPyv6l7dfwMiylkBpR17ii?usp=sharing",
          externalTarget: true,
        },
        {
          title: "Vichada",
          target:
            "https://drive.google.com/drive/folders/1swKAFNbcThVxbhzU56BTPzvuvslX1bWq?usp=sharing",
          externalTarget: true,
        },
        {
          title: "Día del Amigo",
          target:
            "https://drive.google.com/drive/folders/1VRx4z8ys10OMuIwBg8H-uQWsuuFrD30e?usp=sharing",
          externalTarget: true,
        },
        {
          title: "Inauguración R21",
          target:
            "https://drive.google.com/drive/folders/1Nzpzx6pJWeY6f_Vum9TvRKk8ICPrQEP5?usp=sharing",
          externalTarget: true,
        }, */
      ],
    };
  },
  computed: {
    isDesktop(): boolean {
      return (
        this.state.device === ScreenType.smallDesktop ||
        this.state.device === ScreenType.largeDesktop
      );
    },
  },
});
